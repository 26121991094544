<template>
  <v-chip :class="`v-chip-light-bg ${type}--text`" :color="type">
    {{ value ? $t("Active") : $t("Inactive") }}
  </v-chip>
</template>

<script>
export default {
  name: "ActiveChip",
  props: {
    value: Boolean,
  },
  computed: {
    type() {
      return this.value ? "success" : "error";
    },
  },
};
</script>

<style></style>
