<template>
  <wrapper-table :title="t('Users list')">
    <template #subtitle>
      <a-link
        :title="t('Add user')"
        :icon="mdiPlus"
        @click.prevent="onAddTemplates"
      />
    </template>
    <template #actions-header></template>
    <user-list />

    <wrapper-drawer v-model="isAddUser" :title="t('Add user')">
      <user-form is-add />
    </wrapper-drawer>
  </wrapper-table>
</template>

<script>
import { reactive, ref } from "@vue/composition-api";
import UserList from "@/components/users/UserList";
import WrapperTable from "@/components/general/WrapperTable";
import FormTextField from "@/components/forms/TextField";
import FormSelect from "@/components/forms/Select";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import WrapperForm from "@/components/general/WrapperForm";
import ALink from "@/components/general/ALink";
import UserForm from '@/components/users/UserForm';
import { required } from "@core/utils/validation";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { mdiPlus } from "@mdi/js";

export default {
  components: {
    UserList,
    WrapperTable,
    WrapperDrawer,
    WrapperForm,
    FormTextField,
    FormSelect,
    ALink,
    UserForm,
  },

  setup(props) {
    const { t } = useUtilsI18n();
    const isAddUser = ref(false);
    const isFormValid = ref(false);

    const onAddTemplates = () => {
      isAddUser.value = true;
    };

    const form = reactive({
      role: null,
      name: null,
      email: null,
    });


    return {
      form,
      isFormValid,
      isAddUser,

      mdiPlus,

      validators: {
        required,
      },

      onAddTemplates,
      t,
    };
  },
};
</script>

<style></style>
