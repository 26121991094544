<template>
  <FormSelect
    :value="value"
    :placeholder="$t('Status')"
    :label="$t('Status')"
    itemText="name"
    itemValue="id"
    :items="status"
    v-bind="$attrs"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
import { ref, getCurrentInstance } from "@vue/composition-api";
import FormSelect from "@/components/forms/Select";
import api from "@/services";

export default {
  name: "ActiveSelect",
  components: {
    FormSelect,
  },
  props: {
    value: {
      default: null,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const status = ref([
      {
        id: false,
        name: vm.$t("Inactive")
      },
      {
        id: true,
        name: vm.$t("Active")
      }
    ]);

    return {
      status,
    };
  },
};
</script>

<style></style>
