<template>
  <FormSelect
    :value="value"
    :placeholder="$t('Select role')"
    :label="$t('Select role')"
    :itemText="(item) => $t(item.name)"
    itemValue="id"
    :items="roles"
    v-bind="$attrs"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import FormSelect from "@/components/forms/Select";
import api from "@/services";

export default {
  name: "RoleSelect",
  components: {
    FormSelect,
  },
  props: {
    value: {
      default: null,
    },
  },
  setup(props) {
    const roles = ref([]);

    const getRoles = async () => {
      const { data: response } = await api.getRoles();
      const { data } = response;
      roles.value = data;
    };

    onMounted(() => {
      getRoles();
    });
    return {
      roles,
    };
  },
};
</script>

<style></style>
