<template>
  <span>{{ text }}</span>
</template>

<script>
export default {
  name: "UserLabelRoleCountry",
  props: {
    role: String,
  },
  computed: {
    text() {
      const roleArr = this.role.split(" ");
      if (!roleArr.length) return null;
      return `${this.$t(roleArr[0])} ${roleArr[1]}`;
    },
  },
};
</script>

<style></style>
