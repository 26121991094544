<template>
  <FormSelect
    :value="value"
    :placeholder="t('Country')"
    :label="t('Country')"
    :items="filterCountries"
    :rules="rules"
    itemText="name"
    itemValue="id"
    @onChangeSelect="onChangeSelect"
    v-bind="$attrs"
  />
</template>

<script>
import FormSelect from "@/components/forms/Select";
import api from "@/services";
import { ref, computed, onMounted } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";

export default {
  props: {
    value: {
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FormSelect,
  },

  setup(props, { emit }) {
    const countries = ref([]);
    const { t } = useUtilsI18n();

    const filterCountries = computed(() =>
      countries.value.filter(
        (c) => c.code === "ar" || c.code == "fr" || c.code === "es"
      )
    );

    const onChangeSelect = (value) => {
      emit("onChangeSelectCountries", value);
      emit("input", value);
    };

    const getCountries = async () => {
      try {
        const { data: response } = await api.getCountries();
        const { data } = response;

        countries.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      getCountries();
    });

    return {
      countries,
      filterCountries,

      getCountries,
      onChangeSelect,

      t,
    };
  },
};
</script>

<style></style>
