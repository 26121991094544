<template>
  <wrapper-form v-model="isFormValid" :model="form" ref="form">
    <v-row>
      <v-col cols="12" col v-if="isAdd">{{ $t("MESSAGE_NEW_USER") }}</v-col>

      <v-col cols="12" col>
        <role-select v-model="form.role_id" :rules="[validators.isSelected]" />
      </v-col>
      <v-col cols="12" col>
        <FormTextField
          :label="$t('FirstName')"
          :rules="[validators.required]"
          v-model="form.first_name"
        />
      </v-col>
      <v-col cols="12" col>
        <FormTextField
          :label="$t('LastName')"
          :rules="[validators.required]"
          v-model="form.last_name"
        />
      </v-col>
      <v-col cols="12" col>
        <FormTextField
          :label="$t('Email')"
          :rules="[validators.required]"
          v-model="form.email"
        />
      </v-col>

      <v-col cols="12" col>
        <SelectCountries
          v-model="form.country_id"
          :rules="[validators.isSelected]"
        />
      </v-col>

      <template v-if="isEdit">
        <v-col cols="12" col>
          <FormTextField :label="$t('Phone')" v-model="form.phone" />
        </v-col>

        <v-col cols="12" col>
          <FormTextField :label="$t('Position')" v-model="form.job_position" />
        </v-col>

        <v-col cols="12" col>
          <active-select v-model="form.active" />
        </v-col>
      </template>

      <v-col cols="12" xs="12" sm="6">
        <v-btn
          color="primary"
          type="submit"
          :disabled="!isFormValid"
          @click.prevent="onSubmitForm"
        >
          {{ isAdd ? $t("Invite send") : $t("Save") }}
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="6">
        <a-link
          :title="$t('Delete user')"
          class="d-block text-right"
          @click.prevent="onUserDelete"
          v-if="isEdit"
        />
      </v-col>
    </v-row>
  </wrapper-form>
</template>

<script>
import { reactive, ref, getCurrentInstance } from "@vue/composition-api";

import FormTextField from "@/components/forms/TextField";
import WrapperForm from "@/components/general/WrapperForm";
import RoleSelect from "@/components/roles/RoleSelect";
import SelectCountries from "@/components/general/SelectCountries";
import ALink from "@/components/general/ALink";
import { required, isSelected } from "@core/utils/validation";
import api from "@/services";
import store from "@/store";
import ActiveSelect from "../general/ActiveSelect.vue";

const defaultForm = {
  role_id: null,
  first_name: null,
  last_name: null,
  email: null,
  country_id: null,
  phone: null,
  job_position: null,
  active: 1,
};

export default {
  name: "UserForm",
  components: {
    FormTextField,
    WrapperForm,
    RoleSelect,
    SelectCountries,
    ALink,
    ActiveSelect,
  },
  props: {
    isAdd: Boolean,
    isEdit: Boolean,
    model: Object,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const { isAdd, isEdit, model = null } = props;
    const isFormValid = ref(false);

    const form = reactive(model || defaultForm);

    const onFormAdd = async () => {
      try {
        const { data: response } = await api.addUsers(form);
        const { data, success } = response;

        if (success) {
          store.commit("users/SET_NEW_USER", data);
          vm.$refs.form.$children[0].reset();
        }

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    const onFormUpdate = async () => {
      try {
        const { id: userId } = model;
        const { data: response } = await api.updateUsersById(userId, model);
        const { success, data: user } = response;
        emit("on-user-edit", { user });

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    const onUserDelete = async () => {
      try {
        const { id: userId } = model;
        await api.deleteUsersById(userId);
        emit("on-user-delete", { user: model });

        vm.$alert(vm.$t("MessageSuccess"), null, "success");
      } catch (error) {
        vm.$alert(vm.$t("MessageError"), null, "error");
      }
    };

    const onSubmitForm = () => {
      if (isAdd) onFormAdd();
      if (isEdit) onFormUpdate();
    };

    return {
      form,
      isFormValid,

      validators: {
        required,
        isSelected,
      },

      onSubmitForm,
      onUserDelete,
    };
  },
};
</script>

<style></style>
