<template>
  <div class="">
    <v-data-table
      :headers="headers"
      :items="users"
      :items-per-page="15"
      :loading="loading"
      @click:row="onEditUser"
    >
      <template #[`item.user`]="{ item }">
        <div class="d-flex align-center">
          <icon-avatar :text="item.full_name" color="primary" />
          <div class="d-flex flex-column ms-3">
            <span
              class="d-block font-weight-semibold text--primary text-truncate"
              >{{ item.full_name }}</span
            >
            <small>{{ item.job_position }}</small>
          </div>
        </div>
      </template>
      <template #[`item.role_country`]="{ item }">
        <span class="text-uppercase font-semi-bold"
          ><strong><user-label-role-country :role="item.role_country" /></strong
        ></span>
      </template>

      <template #[`item.active`]="{ item }">
        <active-chip v-model="item.active" />
      </template>

      <template #[`item.actions`]></template>
    </v-data-table>

    <wrapper-drawer v-model="isEditUser" :title="t('Edit user')">
      <user-form
        is-edit
        :model="userSelect"
        @on-user-edit="onUserEdit"
        @on-user-delete="onUserDelete"
        v-if="userSelect"
      />
    </wrapper-drawer>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import IconAvatar from "@/components/icons/IconAvatar";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import ActiveChip from "@/components/general/ActiveChip";
import UserForm from "./UserForm.vue";
import { required } from "@core/utils/validation";
import api from "@/services";
import store from "@/store";
import UserLabelRoleCountry from "./UserLabelRoleCountry.vue";

export default {
  name: "UserList",
  components: {
    IconAvatar,
    WrapperDrawer,
    UserForm,
    ActiveChip,
    UserLabelRoleCountry,
  },
  setup(props) {
    const { t } = useUtilsI18n();
    const userSelect = ref(null);
    const isEditUser = ref(false);
    const isFormValid = ref(false);
    const users = ref([]);
    const loading = ref(false);

    const headers = computed(() => {
      return [
        { text: t("Role"), value: "role_country" },
        { text: t("User"), value: "user" },
        { text: t("Email"), value: "email" },
        // { text: t("Areas"), value: "areas" },
        { text: t("State"), value: "active" },
        { text: "", value: "actions" },
      ];
    });

    const onEditUser = (user) => {
      userSelect.value = user;

      isEditUser.value = true;
    };

    const getUsers = async () => {
      try {
        loading.value = true;
        const { data: response } = await api.getUsers();
        const { data } = response;
        users.value = data;
      } catch (error) {
      } finally {
        loading.value = false;
      }
    };

    const onUserEdit = ({ user }) => {
      try {
        const userIndex = users.value.findIndex((i) => i.id === user.id);
        users.value.splice(userIndex, 1, { ...user });
      } catch (error) {}
    };

    const onUserDelete = ({ user }) => {
      try {
        const userIndex = users.value.findIndex((i) => i.id === user.id);
        users.value.splice(userIndex, 1);
      } catch (error) {}
    };

    watch(isEditUser, (v) => {
      if (!v) userSelect.value = null;
    });

    watch(
      () => store.state.users.hasNewUser,
      (value) => {
        users.value.unshift(value);
      }
    );

    onMounted(() => {
      getUsers();
    });

    return {
      users,
      userSelect,
      isEditUser,
      isFormValid,
      headers,
      loading,

      validators: {
        required,
      },

      onEditUser,
      onUserEdit,
      onUserDelete,
      t,
    };
  },
};
</script>

<style></style>
